import React, { ChangeEvent, SyntheticEvent } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import hotkeys from 'hotkeys-js'
import { v4 as uuid } from "uuid"

import appStates from "../utils/states"
import { simpleSort } from "../utils/funcs"
import { Caption, export2srt, captionsCompare, getLanguages, Language, getSRTfromVideo } from "../utils/caption"
import { SHOOT_TIME_MINOR, SHOOT_TIME_MAJOR, MAX_HISTORY } from "../utils/consts"

import { VideoPlayer, Timeline, SubtitleTimeline, CaptionEditor, CaptionView, TimeControll } from "../components/video"
import { CircleBtn, pushToast, FileInput } from "../components/form"
import { parseSrt } from "../utils/caption"
import { second2timestamp } from "../utils/timestamp"
import "./studio.sass"
import VideoDefault from "../assets/videodefault.mp4";
import SubtitleDefault from "../assets/subtitledefault.srt";

const fileDownload = require('js-file-download')

function copyReplace<T>(arr: T[], i: number, repl: T): T[] {
  return Object.assign([], arr, { [i]: repl })
}

export default class Studio extends React.Component<{}, {
  videoUrl: string,
  videoFname:string,
  //subtitleUrl: string,
  videoHeight: number
  acc: number
  progressGenerate:boolean
  subFileName: string

  currentTime: number
  totalTime: number

  languages: Language[]
  language:string
  captions: Caption[]
  selected_caption_i: number | null
  
  history: Caption[][]
  historyCursor: number
}> {

  VideoPlayerRef: React.RefObject<VideoPlayer>
  videoInput: React.RefObject<FileInput>
  itemRefs:HTMLDivElement []
  capRefs:React.RefObject<HTMLDivElement>

  constructor(props: any) {
    super(props)
    this.state = {
      videoUrl: VideoDefault,
      videoFname:'',
      //subtitleUrl:'',
      videoHeight: 400,
      acc: 0,
      progressGenerate:false,
      currentTime: 0,
      totalTime: 0,

      subFileName: "subtitle.srt",

      languages:[],
      language:"en-us",
      captions: [],
      selected_caption_i: null,

      historyCursor: -1,
      history: [],
    }
    this.itemRefs=[]
    this.capRefs=React.createRef()
    this.VideoPlayerRef = React.createRef()
    this.videoInput = React.createRef()

    // --- bind methods ---
    this.handler = this.handler.bind(this)
    this.loadCaptions = this.loadCaptions.bind(this)
    this.loadVideo = this.loadVideo.bind(this)
    this.changeLanguage=this.changeLanguage.bind(this)
    this.autoGenerate=this.autoGenerate.bind(this)
    this.onTimeUpdate = this.onTimeUpdate.bind(this)
    this.onVideoError = this.onVideoError.bind(this)
    this.handleSeparatorDrag = this.handleSeparatorDrag.bind(this)
    this.handleSeparatorStop = this.handleSeparatorStop.bind(this)

    this.addCaptionUIHandler = this.addCaptionUIHandler.bind(this)
    this.changeCaptionUIHandler = this.changeCaptionUIHandler.bind(this)
    this.changeCaptionObject = this.changeCaptionObject.bind(this)
    this.deleteCaptionUIHandler = this.deleteCaptionUIHandler.bind(this)
    this.deleteallCaptionUIHandler = this.deleteallCaptionUIHandler.bind(this)
    this.deleteCaptionObject = this.deleteCaptionObject.bind(this)
    this.captionSelectionToggle = this.captionSelectionToggle.bind(this)

    this.updatedHistory = this.updatedHistory.bind(this)
    this.undoRedo = this.undoRedo.bind(this)

    this.goToLastStart = this.goToLastStart.bind(this)
    this.goToNextEnd = this.goToNextEnd.bind(this)

    this.saveFile = this.saveFile.bind(this)
    this.onCaptionTimeRangeChanged=this.onCaptionTimeRangeChanged.bind(this)
  }

  // ------------- component API -----------------

  async componentDidMount() {
    // --- init states ---
    let initCaps=[]
    let localCaptions=localStorage.getItem('captions')
    if(localCaptions) initCaps=JSON.parse(localCaptions)
    else{
      const response = await fetch(SubtitleDefault),
      data = await response.text()
      initCaps = parseSrt(data)
    }
    //let languages=await getLanguages()
    this.setState({
      captions: initCaps,
      //languages:languages,
      ...this.updatedHistory(initCaps)
    })

    // --- bind shortcuts ---
    hotkeys.filter = () => true // to make it work also in input elements

    hotkeys('alt+*, tab, shift+tab', kv => kv.preventDefault())

    hotkeys('ctrl+shift+left', kv => {
      if (this.state.selected_caption_i === null) {
        kv.preventDefault()
        this.VideoPlayerRef.current?.shootTime(-SHOOT_TIME_MAJOR)
      }
    })
    hotkeys('ctrl+shift+right', kv => {
      if (this.state.selected_caption_i === null) {
        kv.preventDefault()
        this.VideoPlayerRef.current?.shootTime(+SHOOT_TIME_MAJOR)
      }
    })
    hotkeys('ctrl+6', kv => {
      kv.preventDefault()
      this.goToLastStart()
    })
    hotkeys('ctrl+7', kv => {
      kv.preventDefault()
      this.goToNextEnd()
    })
    hotkeys('ctrl+left', kv => {
      kv.preventDefault()
      this.VideoPlayerRef.current?.shootTime(-SHOOT_TIME_MINOR)
    })
    hotkeys('ctrl+right', kv => {
      kv.preventDefault()
      this.VideoPlayerRef.current?.shootTime(+SHOOT_TIME_MINOR)
    })

    hotkeys('ctrl+enter', () => this.addCaptionUIHandler())

    hotkeys('ctrl+down', kv => {
      kv.preventDefault()

      const t = this.state.currentTime,
        i = this.state.captions.findIndex(c => t >= c.start && t <= c.end)

      if (i !== -1)
        this.setState({ selected_caption_i: i })
    })
    hotkeys('escape', kv => {
      kv.preventDefault()
      this.setState({ selected_caption_i: null })
    })

    hotkeys('space', kv => {
      // @ts-ignore
      if (kv.target.tagName !== 'INPUT')
        kv.preventDefault()
    })
    hotkeys('ctrl+space', kv => {
      this.VideoPlayerRef.current?.togglePlay()
    })

    hotkeys('ctrl+delete', () => this.deleteCaptionUIHandler())

    hotkeys('ctrl+z', () => this.undoRedo(true))
    hotkeys('ctrl+y', () => this.undoRedo(false))
    hotkeys('ctrl+s', kv => {
      kv.preventDefault()
      this.saveFile()
    })

  }
  componentWillUnmount() {
    hotkeys.unbind()
  }

  // ----------- video player events -------------------

  onTimeUpdate(nt: number) { // nt: new time
    // const sci = this.state.selected_caption_i,
        const ve = this.VideoPlayerRef,
        isplying = ve.current?.isPlaying()
    // if (sci !== null) {
    //   const cap = this.state.captions[sci+1]

    //   if (cap!=null && nt >= cap.start && isplying) {
    //     this.setState({ selected_caption_i: sci+1 })
    //       // ve.current?.setPlay(false)
    //       // ve.current?.setTime(cap.start)
    //   }
    //   // else if (nt < cap.start) {
    //   //   if (isplying)
    //   //     ve.current?.setTime(cap.start)
    //   // }
    // }else{
    //   if(isplying){
    //     this.state.captions.forEach((value, index) => {
    //       if(nt>=value.start && nt<=value.end) this.setState({ selected_caption_i: index })
    //     });
    //   } 
    // }
    if(isplying){
      let breakMe = false;
      this.state.captions.forEach((value, index) => {
        if(breakMe===false && nt>=value.start && nt<=value.end) {
          this.capRefs.current?.scrollTo({
            top: this.itemRefs[index]?.offsetTop-201,
            behavior: "smooth"
          })
          //this.itemRefs[index]?.scrollIntoView();
          this.setState({ selected_caption_i: index })
          breakMe=true;
        }
      });
    }
    this.setState({ currentTime: nt })
  }

  onVideoError(e: SyntheticEvent) {
    pushToast({
      kind: 'danger',
      message: "error happend while loading video",
      duration: 5000
    })
  }
  // load video and caption
  async loadCaptions(url: string, fname: string) {
    if (url === '') return

    const
      response = await fetch(url),
      data = await response.text()

    // appStates.subtitles.setData(parseSrt(data))

    this.setState({
      //subtitleUrl: url,
      subFileName: fname,
      captions: parseSrt(data),
      ...this.updatedHistory(parseSrt(data))
    })
  }

  loadVideo(url: string, name: string) {
    // appStates.videoUrl.setData(url)
    this.setState({ videoUrl: url, videoFname: name })
  }

  handler(f: File, fileType: "video" | "subtitle") {
    const blob = URL.createObjectURL(f)

    if (fileType === 'video')
      this.loadVideo(blob, f.name)

    else
      this.loadCaptions(blob, f.name)
  }
  // ----------------- functionalities --------------------
  // -- captions changes
  changeCaptionObject(index: number, newcap: Caption): Caption[] {
    return copyReplace(
      this.state.captions,
      index,
      { ...newcap, hash: uuid() })
  }
  deleteCaptionObject(selected_i: number): Caption[] {
    let
      copy = [...this.state.captions],
      lastIndex = copy.length - 1

    if (selected_i !== lastIndex)
      copy[selected_i] = copy[lastIndex]

    copy.pop()

    return copy
  }

  getIndexCaption(captions:Caption[],caption:Caption){
    let breakMe = false;
    let capIndex=null;
    captions.forEach((value, index) => {
      if(breakMe===false && caption.start===value.start && caption.end===value.end) {
        capIndex=index
        breakMe=true;
      }
    });
    return capIndex;
  }

  addCaptionUIHandler() {
    let
      ct = this.state.currentTime,
      currentCap = this.state.captions.find(c => (ct >= c.start) && (ct <= c.end)),
      t = currentCap && (currentCap.end - ct < 0.6) ? currentCap.end + 0.001 : ct,
      newCap = {
        start: t,
        end: t + 1,
        content: "New Caption",
        hash: uuid(),
      },
      caps = this.state.captions.concat(newCap).sort(captionsCompare)

    this.setState({
      captions: caps,
      selected_caption_i: this.getIndexCaption(caps,newCap),
      ...this.updatedHistory(caps),
    })
    this.capRefs.current?.scrollTo({
      top: this.itemRefs[this.getIndexCaption(caps,newCap) || 0]?.offsetTop-201,
      behavior: "smooth"
    })
  }
  changeCaptionUIHandler(index: number, newCap: Caption) {
    if (index < 0 || index >= this.state.captions.length) return // it can happen due to fast repeative user actions

    let caps = this.changeCaptionObject(index, newCap)

    this.setState({
      captions: caps,
      ...this.updatedHistory(caps),
    })
  }
  deleteCaptionUIHandler() {
    if (this.state.selected_caption_i === null) return

    let caps = this.deleteCaptionObject(this.state.selected_caption_i)

    this.setState({
      selected_caption_i: null,
      captions: caps,
      ...this.updatedHistory(caps),
    })
  }

  deleteallCaptionUIHandler() {
    let currentTime=0
    this.VideoPlayerRef.current?.setTime(currentTime)
    this.setState({
      selected_caption_i: null,
      captions: [],
      currentTime:currentTime,
      ...this.updatedHistory([]),
    })
    localStorage.removeItem("captions");
  }
  updatedHistory(caps: Caption[]): object {
    localStorage.setItem("captions", JSON.stringify(caps));
    let
      h = this.state.history,
      li = h.length - 1, // last index
      c = this.state.historyCursor

    if (c < li)
      h = [...h.slice(0, c + 1), caps]
    else
      h = [...h, caps]

    h = h.slice(-MAX_HISTORY)

    return {
      historyCursor: h.length - 1,
      history: h
    }
  }
  undoRedo(undo: boolean) {
    const
      hc = this.state.historyCursor,
      history = this.state.history,
      dir = (undo ? -1 : +1)

    // out of range check
    if (hc + dir < -1 || hc + dir >= history.length) return

    this.setState({
      selected_caption_i: null,
      captions: this.state.history[hc + (undo ? 0 : +1)],
      historyCursor: hc + dir,
    })
  }

  // -- caption selection

  captionSelectionToggle(index: number | null) {
    let currentTime=index!=null?this.state.captions[index].start:0;
    this.VideoPlayerRef.current?.setPlay(false)
    this.VideoPlayerRef.current?.setTime(currentTime)
    this.setState({
      currentTime:currentTime,
      selected_caption_i: index
    })
    this.capRefs.current?.scrollTo({
      top: this.itemRefs[index || 0]?.offsetTop-201,
      behavior: "smooth"
    })
  }

  goToNextEnd() {
    // TODO optimize
    const
      ls = this.state,
      ends = ls.captions
        .filter(c => ls.currentTime < c.end)
        .map(c => c.end)
        .sort(simpleSort)

    if (ends.length)
      this.VideoPlayerRef.current?.setTime(ends[0])
  }

  goToLastStart() {
    const
      ls = this.state,
      starts = ls.captions
        .filter(c => ls.currentTime > c.start)
        .map(c => c.start)
        .sort(simpleSort)

    if (starts.length)
      this.VideoPlayerRef.current?.setTime(starts[starts.length - 1])
  }

  saveFile() {
    this.state.captions.sort(captionsCompare)
    fileDownload(export2srt(this.state.captions), this.state.subFileName)
  }

  handleSeparatorStop(e: DraggableEvent, dd: DraggableData) {
    this.setState({
      videoHeight: this.state.videoHeight + this.state.acc,
      acc: 0,
    })
  }
  handleSeparatorDrag(e: DraggableEvent, dd: DraggableData) {
    this.setState({ acc: this.state.acc + dd.deltaY })
  }
  changeLanguage(event:ChangeEvent<HTMLSelectElement>){
    this.setState({language: event.currentTarget.value});
  }
  onCaptionTimeRangeChanged(cap:Caption,startChange: number | null = 0, endChange: number | null = 0) { // null is the value for stick time button
  
    if (cap === null) return

    // controll the caption start/end time 
    if (startChange === null)
      cap.start = this.state.currentTime + 0.001
    else if (this.isCapInTimeRange(cap.start + startChange))
      cap.start += startChange

    if (endChange === null)
      cap.end = this.state.currentTime
    else if (this.isCapInTimeRange(cap.end + endChange))
      cap.end += endChange

    // sync end & start
    if (cap.start > cap.end) {
      if (endChange === 0)
        cap.end = cap.start
      else
        cap.start = cap.end
    }
  }
  isCapInTimeRange(time: number): boolean {
    return time >= 0 && time <= this.state.totalTime
  }
  async autoGenerate(){
    if(this.videoInput.current?.fileInput.current?.files?.length){
      this.setState({progressGenerate:true})
      let caps=await getSRTfromVideo(this.videoInput.current,this.state.language)
      this.setState({progressGenerate:false})
      this.setState({
        captions: caps,
        ...this.updatedHistory(caps)
      })
    }else{
      alert("No *.mp4 or *.mp3 file selected.")
    }
  }
  render() {
    const
      caps = this.state.captions,
      selected_ci = this.state.selected_caption_i
    return (<>
      {/* <h2 className="page-title">Studio</h2> */}
      <div className="container">
        <div className="row py-3">
          <div className="col-lg-7">
            <VideoPlayer
              ref={this.VideoPlayerRef}
              videoUrl={this.state.videoUrl}
              onTimeUpdate={this.onTimeUpdate}
              onError={this.onVideoError}
              height={this.state.videoHeight}
              onDurationChanges={du => this.setState({ totalTime: du })}
            />
            <CaptionView 
              caption={selected_ci === null ? null : caps[selected_ci]}
            />
          </div>
          <div className="col-lg-5">
            <div className='card p-2'>
              {/* <div className='row'>
                <div className="col-lg-2">
                  <FileInput
                    ref={this.videoInput}
                    onChange={f => this.handler(f, 'video')}
                    accept=".mp3,.mp4"
                    btnText="open .mp4 or .mp3"
                    iconClassName="fa fa-video"
                  />
                </div>
                <div className="col-lg-6">
                  <select className="form-control" id="formLanguageSelect" onChange={this.changeLanguage}  value={this.state.language}>
                  {this.state.languages?.map((c, i) => <option key={i} value={c.key}>{c.value}</option>)}
                  </select>
                </div>
                <div className="col-lg-4">
                  {this.state.progressGenerate===false?<button type="button" onClick={this.autoGenerate} className="btn btn-primary">Generate</button>
                    :<div className="spinner-generate"></div>
                  }
                </div>
              </div> */}
              <div className="d-flex action-button-group mt-2">
                <div className="mx-2">
                <FileInput
                    ref={this.videoInput}
                    onChange={f => this.handler(f, 'video')}
                    accept=".mp3,.mp4"
                    btnText="open .mp4 or .mp3"
                    iconClassName="fa fa-video"
                  /></div>
                <div className="mx-2">
                  <FileInput
                  onChange={f => this.handler(f, 'subtitle')}
                  accept=".srt"
                  btnText="open caption"
                  iconClassName="fa fa-closed-captioning"
                /></div>
                <CircleBtn
                  iconClassName="fas fa-plus"
                  className='mx-2'
                  text="add caption"
                  onClick={this.addCaptionUIHandler}
                />
                <CircleBtn
                  iconClassName="fas fa-undo"
                  disabled={this.state.historyCursor === -1}
                  className='mx-2'
                  text="undo"
                  onClick={() => this.undoRedo(true)}
                />

                <CircleBtn
                  iconClassName="fas fa-redo"
                  className='mx-2'
                  text="redo"
                  disabled={this.state.historyCursor >= this.state.history.length - 1}
                  onClick={() => this.undoRedo(false)}
                />

                <CircleBtn
                  iconClassName="fas fa-check"
                  disabled={this.state.selected_caption_i === null}
                  className='mx-2'
                  text="unselect"
                  onClick={() => this.setState({ selected_caption_i: null })}
                />

                {/* <CircleBtn
                  iconClassName="fas fa-times"
                  disabled={this.state.selected_caption_i === null}
                  className='mx-2'
                  text="remove caption"
                  onClick={this.deleteCaptionUIHandler}
                /> */}

                <CircleBtn
                  iconClassName="fas fa-trash"
                  disabled={caps.length===0}
                  className='mx-2'
                  text="clear all"
                  onClick={this.deleteallCaptionUIHandler}
                />

                <CircleBtn
                  iconClassName="fas fa-chevron-left"
                  disabled={caps.length === 0}
                  className='mx-2'
                  text="go start caption"
                  onClick={this.goToLastStart}
                />
                <CircleBtn
                  iconClassName="fas fa-chevron-right"
                  disabled={caps.length === 0}
                  className='mx-2'
                  text="go end caption"
                  onClick={this.goToNextEnd}
                />
                <button title='Download (*.srt)' className="btn btn-primary mx-2" onClick={this.saveFile}>
                  <strong><span className="fas fa-download"></span></strong>
                </button>
              </div>
            </div>
            <div ref={this.capRefs} style={{height:"380px",overflowY:"scroll"}}>
            {caps?.map((c: Caption, i) =>
            <div ref={el =>{if(el!==null) this.itemRefs[i] = el}} key={i} className='position-relative'>
              <div className='position-absolute end-0 top-0 z-1'>
                  <CircleBtn
                    iconClassName="fas fa-times"
                    text="remove caption"
                    className=''
                    onClick={()=>{
                      let caps = this.deleteCaptionObject(i)
                      this.setState({
                        selected_caption_i: null,
                        captions: caps,
                        ...this.updatedHistory(caps),
                      })
                    }}
                  />
              </div>
              <div onClick={()=>this.captionSelectionToggle(i)} className={i===this.state.selected_caption_i?"border-primary card my-2 p-3":"card my-2 p-3"}>
                {/* <div>{second2timestamp(c.start,"complete")+" : "+second2timestamp(c.end,"complete")}</div> */}
                {/* <div>{c.content}</div> */}
                <div className="caption-editor-wrapper">
                {c ?
                  <TimeControll
                    time={c.start}
                    onChange={changeValue => { this.onCaptionTimeRangeChanged(c,changeValue, 0) }}
                  /> :
                  <TimeControll time={0} />
                }
                {c ?
                  <TimeControll
                    time={c.end}
                    onChange={changeValue => { this.onCaptionTimeRangeChanged(c,0, changeValue) }}
                  /> :
                  <TimeControll time={0} />
                }
                </div>
                <div>
                  <input type="text" disabled={c === null}
                    className={"form-control caption-editor"}
                    value={this.state.captions[i].content || ""}
                    onChange={(e)=> {
                      caps[i].content=e.target.value
                      this.setState({captions:caps})
                    }}
                  />
                </div>
              </div>
            </div>)
            }
            </div>
          </div>
        </div>

        {/* <Draggable
          axis="y"
          position={{ x: 0, y: 0 }}
          onDrag={this.handleSeparatorDrag}
          onStop={this.handleSeparatorStop}
        >
          <div className="separator mt-3"></div>
        </Draggable> */}
        <div className="separator"></div>
        <Timeline
          className="my-2"
          currentTime={this.state.currentTime}
          totalTime={this.state.totalTime}
          onSelectNewTime={nt => this.VideoPlayerRef.current?.setTime(nt)}
        />
  
        {/* <div className="d-flex justify-content-center action-button-group my-2">
          <FileInput
            ref={this.videoInput}
            onChange={f => this.handler(f, 'video')}
            accept=".mp3,.mp4"
            btnText="open video"
            iconClassName="fa fa-video"
          />
          <FileInput
            onChange={f => this.handler(f, 'subtitle')}
            accept=".srt"
            btnText="open caption"
            iconClassName="fa fa-closed-captioning"
          />
          <CircleBtn
            iconClassName="fas fa-plus"
            text="add caption"
            onClick={this.addCaptionUIHandler}
          />
          <CircleBtn
            iconClassName="fas fa-undo"
            disabled={this.state.historyCursor === -1}
            text={"undo " + (this.state.historyCursor + 1)}
            onClick={() => this.undoRedo(true)}
          />

          <CircleBtn
            iconClassName="fas fa-redo"
            text={"redo " + ((this.state.history.length - 1) - this.state.historyCursor)}
            disabled={this.state.historyCursor >= this.state.history.length - 1}
            onClick={() => this.undoRedo(false)}
          />

          <CircleBtn
            iconClassName="fas fa-times"
            disabled={this.state.selected_caption_i === null}
            text="unselect"
            onClick={() => this.setState({ selected_caption_i: null })}
          />

          <CircleBtn
            iconClassName="fas fa-trash"
            disabled={this.state.selected_caption_i === null}
            text="delete"
            onClick={this.deleteCaptionUIHandler}
          />

          <CircleBtn
            iconClassName="fas fa-trash"
            disabled={caps.length===0}
            text="delete all"
            onClick={this.deleteallCaptionUIHandler}
          />

          <CircleBtn
            iconClassName="fas fa-chevron-left"
            disabled={caps.length === 0}
            text="last start"
            onClick={this.goToLastStart}
          />
          <CircleBtn
            iconClassName="fas fa-chevron-right"
            disabled={caps.length === 0}
            text="next end "
            onClick={this.goToNextEnd}
          />
          <button className="btn btn-primary" onClick={this.saveFile}>
            <strong><span className="fas fa-download"></span> Download (*.srt)</strong>
          </button>
        </div> */}

        <SubtitleTimeline
          className="my-1"
          duration={this.state.totalTime}
          currentTime={this.state.currentTime}
          onSelectNewTime={nt => this.VideoPlayerRef.current?.setTime(nt)}

          captions={caps}
          onCaptionSelected={this.captionSelectionToggle}
          selectedCaption_i={selected_ci}
          onCaptionChanged={this.changeCaptionUIHandler}
        />

        {/* <CaptionEditor
          currentTime={this.state.currentTime}
          totalTime={this.state.totalTime}
          caption={selected_ci === null ? null : caps[selected_ci]}
          captionIndex={selected_ci === null ? -1 : selected_ci}
          onCaptionChanged={this.changeCaptionUIHandler}
        /> */}

      </div>

      {/* <div className="d-flex justify-content-center my-2 download-form">
        <input className="form-control" placeholder="file-name.srt" 
          value={this.state.subFileName} 
          onChange={ e => this.setState({subFileName: e.currentTarget.value})}  />
        <button className="btn btn-danger" onClick={this.saveFile}>
          <strong> save as a file <span className="fas fa-file"></span>  </strong>
        </button>
      </div> */}
    </>)
  }
}